import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCiselnikJazyk, Jazyk } from "../../locales/i18n";
import AuthContext from "./auth-context";
import LoadingPage from "../../pages/initialLoad/Loading";
import LoadingErrorPage from "../../pages/initialLoad/LoadingError";
import {
  API_Clients,
  DataResultType,
  MAX_NUMBER_OF_ITEMS,
} from "./dataApi/Data";

type TranslationContextType = {
  tui: (key: string) => string;
  tuiz: (key: string) => string;
  getFallbackJazyk: () => string;
};

const TranslationContext = createContext<TranslationContextType | null>(null);

export const useUITranslation = () =>
  useContext(TranslationContext) as TranslationContextType;

type Props = {
  children: React.ReactNode;
};

export const TranslationProvider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const { isLoggedIn } = useContext(AuthContext);
  const [isLoadError, setIsLoadError] = useState(false);

  const [cisUIrozhranieTextacie, setCisUIrozhranieTextacie] =
    useState<Awaited<DataResultType<"/cis/api/CisUIrozhranieTextacie/List">>>();

  const [cisUIrozhranieZiadosti, setCisUIrozhranieZiadosti] =
    useState<Awaited<DataResultType<"/cis/api/CisUIrozhranieZiadosti/List">>>();

  const tui = (lokalizacny_kod: string) => {
    if (cisUIrozhranieTextacie) {
      const label = cisUIrozhranieTextacie.data?.records
        ?.find(
          (i) =>
            i.lokalizacny_kod === lokalizacny_kod &&
            i.jazyk === getCiselnikJazyk(i18n.language)
        )
        // escape double quotes - otherwise JSON.parse() would crash
        ?.textacia?.replaceAll('"', '\\"');

      // JSON.parse(`"${yourString}"`)
      // is used to replace the Unicode escape sequences with their corresponding characters
      // and display the text correctly in the app
      if (label) return JSON.parse(`"${label}"`);

      const fallBackLanguageLabel = cisUIrozhranieTextacie.data?.records
        ?.find(
          (i) =>
            i.lokalizacny_kod === lokalizacny_kod &&
            i.jazyk === getFallbackJazyk()
        )
        // escape double quotes - otherwise JSON.parse() would crash
        ?.textacia?.replaceAll('"', '\\"');
      return fallBackLanguageLabel
        ? JSON.parse(`"${fallBackLanguageLabel}"`)
        : lokalizacny_kod;
    }

    return lokalizacny_kod;
  };

  const tuiz = (lokalizacny_kod: string) => {
    if (cisUIrozhranieZiadosti) {
      const label = cisUIrozhranieZiadosti.data?.records
        ?.find(
          (i) =>
            i.lokalizacny_kod === lokalizacny_kod &&
            i.jazyk === getCiselnikJazyk(i18n.language)
        )
        // escape double quotes - otherwise JSON.parse() would crash
        ?.textacia?.replaceAll('"', '\\"');

      // JSON.parse(`"${yourString}"`)
      // is used to replace the Unicode escape sequences with their corresponding characters
      // and display the text correctly in the app
      if (label) return JSON.parse(`"${label}"`);

      const fallBackLanguageLabel = cisUIrozhranieZiadosti.data?.records
        ?.find(
          (i) =>
            i.lokalizacny_kod === lokalizacny_kod &&
            i.jazyk === getFallbackJazyk()
        )
        // escape double quotes - otherwise JSON.parse() would crash
        ?.textacia?.replaceAll('"', '\\"');
      return fallBackLanguageLabel
        ? JSON.parse(`"${fallBackLanguageLabel}"`)
        : lokalizacny_kod;
    }

    return lokalizacny_kod;
  };

  const getFallbackJazyk = () => {
    if (isLoggedIn) {
      // TODO replace with real function
      const isUserSlovak = true;
      if (isUserSlovak) {
        return getCiselnikJazyk(Jazyk.sk);
      } else {
        return getCiselnikJazyk(Jazyk.en);
      }
    } else {
      return getCiselnikJazyk(Jazyk.sk);
    }
  };

  useEffect(() => {
    API_Clients()
      .then((clients) => {
        clients
          .CIS_Post("/api/CisUIrozhranieTextacie/List", {
            body: {
              filters: [{ aktualny: true, platny: true }],
              paging: {
                currentPage: 1,
                recordsPerPage: MAX_NUMBER_OF_ITEMS,
              },
              sorting: [{}],
            },
          })
          .then((counter) => {
            if (counter) {
              setCisUIrozhranieTextacie(counter);
            } else {
              console.error("failed to getCiselnik[CisUIrozhranieTextacie]");
              setIsLoadError(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoadError(true);
          });
        clients
          .CIS_Post("/api/CisUIrozhranieZiadosti/List", {
            body: {
              filters: [{ aktualny: true, platny: true }],
              paging: {
                currentPage: 1,
                recordsPerPage: MAX_NUMBER_OF_ITEMS,
              },
              sorting: [{}],
            },
          })
          .then((counter) => {
            if (counter.data) {
              setCisUIrozhranieZiadosti(counter);
            } else {
              console.error("failed to getCiselnik[CisUIrozhranieZiadosti]");
              setIsLoadError(true);
            }
          })
          .catch((error) => {
            console.error(error);
            setIsLoadError(true);
          });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadError(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TranslationContext.Provider
      value={{
        tui,
        tuiz,
        getFallbackJazyk,
      }}
    >
      {(cisUIrozhranieTextacie === undefined ||
        cisUIrozhranieZiadosti === undefined) &&
        !isLoadError && <LoadingPage initialValue={20} maxValue={80} />}
      {isLoadError && <LoadingErrorPage />}
      {!(
        cisUIrozhranieTextacie === undefined ||
        cisUIrozhranieZiadosti === undefined
      ) &&
        !isLoadError &&
        children}
    </TranslationContext.Provider>
  );
};
